(function () {
  'use strict';
  angular
  .module('app')
  .component('insuranceInfoApplicant', {
    templateUrl: 'app/components/manage-applications/info_insurance.html',
    controller: InfoInsuranceController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  InfoInsuranceController.$inject = ['$rootScope','globalService','user'];

  function InfoInsuranceController($rootScope, $globalService, $user) {
    var vm = this;
    vm.save = save;
    vm.insurancePeriods = [moment().format('YYYY'), moment().add(1, 'years').format('YYYY')];
    vm.companyRegisterType = [{id: 'no_info_company', name: 'No Info'}, {id: 'new_for_company', name: 'New'}, {id: 'renewal_for_company', name: 'Renewal'}];
    vm.cmsRegisterType = [{id: "no_info_cms", name: 'No Info'}, {id: 'new_for_cms', name: 'New'}, {id: 'renewal_for_cms', name: 'Renewal'}];
    vm.agentRegisterType = [{id: 'no_info_agent', name: 'No Info'}, {id: 'new_for_agent', name: 'New'}, {id: 'renewal_for_agent', name: 'Renewal'}];

    vm.$onInit = function() {
      vm.currentUser = $user.getCurrentUser();
    }


    function save(){
      vm.send = true;

    }

    $rootScope.$on('setCurrentApplicant', function(event, data){
      vm.currentApplication = data.current;
      vm.insurances = data.insurances;
      vm.allCompanies = data.allCompanies;
      vm.companySelected = data.companySelected;
      vm.productSelected = data.productSelected;
      vm.typePlanSelected = data.typePlanSelected;
      vm.planSelected = data.planSelected;
      vm.applicationTypeSelected = data.applicationTypeSelected;
      var periodo = _.filter(vm.insurancePeriods, function(period){ return period == data.current.insurance_period})
      if(periodo.length > 0) {
        vm.insurancePeriodSelected = periodo[0];
      }

      vm.cmsRegisterTypeSelected = _.find(vm.cmsRegisterType, { id: vm.currentApplication.cms_register_type})
      vm.agentRegisterTypeSelected = _.find(vm.agentRegisterType, { id: vm.currentApplication.agent_register_type})
      vm.companyRegisterTypeSelected = _.find(vm.companyRegisterType, { id: vm.currentApplication.company_register_type})

      console.log(vm.cmsRegisterTypeSelected, vm.agentRegisterTypeSelected, vm.companyRegisterTypeSelected)

      
      // vm.companySelected = _.find(vm.allCompanies, {id: vm.currentApplication.insurance_data.company.id});
      // vm.productSelected = _.find(vm.companySelected.products, {id: vm.currentApplication.insurance_data.company_product.id});
      // vm.typePlanSelected = _.find(vm.productSelected.company_product_type, {id: vm.currentApplication.insurance_data.company_product_type.id});
      // vm.planSelected = _.find(vm.typePlanSelected.company_product_plans, {id: vm.currentApplication.insurance_data.company_product_plan.id});
      // vm.applicationTypeSelected = _.find(vm.applicationsType, {id: parseInt(vm.currentApplication.application_type)});
    })

    $rootScope.$on('updateInsuranceData', function(e,data){
      updateInsuranceData()
    })

    function updateInsuranceData(){
      vm.currentApplication.company_product_plan_id = vm.planSelected.id;
      vm.currentApplication.efecty_date = moment(vm.currentApplication.efecty_date).format('MM-DD-YYYY');
      swal({
        title: '¿Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.updateInsuranceData(vm.currentApplication)
            .then(function(res) {
              swal('Exito',res.message,'success');
            }, function() {
              reject('Ha ocurrido un error');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function(data) {
        vm.$onInit();
      });
      
    }

    vm.updateNetPremiun = function(update){
      if(update == 'net_premiun'){

      }else{
        if(parseFloat(vm.currentApplication.subsidy) > parseFloat(vm.currentApplication.cost)){
          vm.currentApplication.beforehand = 0;
        }else{
          vm.currentApplication.beforehand = parseFloat(vm.currentApplication.cost) - parseFloat(vm.currentApplication.subsidy);
        }
      }

    }
  }



})();
