
(function(){
  'use strict';
  angular
    .module('app')
    .component('agentLicense', {
      templateUrl: 'app/components/agent-licenses/agent-licenses.html',
      controller: AgentLicensesController
    });

    AgentLicensesController.$inject = ['user', '$stateParams', '_','$rootScope','globalService']

  function AgentLicensesController($user, $stateParams, _, $rootScope,$globalService) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      vm.loading = true;
      vm.user = {id: $stateParams.id, name: $stateParams.full_name.split('-').join(' ')}
      $rootScope.$emit('uploadBreadCrum', {current: 'Agent Licenses', before: 'Company'});
      $globalService.get_states()
      .then(function(res){
        vm.states = res;
      })
      $globalService.companies.query({lite: true}).$promise
      .then(function(res){
        vm.companies = res;
        vm.loading = false;
      })
      $globalService.users.query({lite: true}).$promise
      .then(function(res){
        vm.users = res;
      })
    }
    
    vm.prepareData = function(){
      vm.loading = true;
      $globalService.getUserLicenses({user_id: vm.user.id, state_id: vm.stateSelected.id})
      .then(function(res){
        vm.loading = false;
        console.log(res.licenses)
        var existing_licenses = res.licenses;
        vm.user.user_credential_attributes = res.credentials;
        vm.user_licenses = _.reduce(vm.companies, function(memo, data){
          var existLicense = _.findWhere(existing_licenses, {company_id: data.id})
          console.log(existLicense)
            memo.push({
              id: existLicense ? existLicense.id : null,
              user_id: parseInt(vm.user.id),
              state_id: parseInt(vm.stateSelected.id),
              company_id: data.id,
              name: data.name,
              appointed: existLicense ? existLicense.appointed : false,
              alreadyCreated: existLicense ? true : false,
              agent_id: existLicense ? existLicense.agent_id : null,
              agentSelected: existLicense ? _.findWhere(vm.users, {id: existLicense.agent_id}) : null,
            })
          
          return memo;
        },[])
        
      })
    }

    vm.save = function(){
      vm.loading = true;
      $globalService.saveLicenses({licenses: vm.user_licenses, user: vm.user})
      .then(function(res){
        vm.loading = false;
        vm.user.user_credential_attributes = res.credentials;
        vm.prepareData();
        swal('Success',res.message,'success')
      },function(err){
        vm.loading = false;
        swal('Error',err.data.message,'info')
      })
    }

    vm.clearLicense = function(license){
      vm.loading = true;
      var params = {
        user_id: vm.user.id,
        state_id: vm.stateSelected.id,
        company_id: license.company_id
      }

      swal({
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.clearLicense(params)
            .then(function(res){
              vm.loading = false;
              vm.prepareData();
              resolve(res.message);
            }, function (err) {
              vm.loading = false;
              reject(err.data.message);
            });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) {
        swal('Success', data.value, 'success');
      })

      // swal({
      //   title: "Are you sure?",
      //   text: "delete this license?",
      //   type: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#DD6B55",
      //   confirmButtonText: "Yes, clear it!",
      //   closeOnConfirm: false
      // },function(){
      //   vm.loading = true;
      //   $globalService.clearLicense(params)
      //   .then(function(res){
      //     vm.loading = false;
      //     vm.prepareData();
      //     swal("Cleared!", "The license has been cleared.", "success");
      //   },function(err){
      //     vm.loading = false;
      //     swal("Error!", err.data.message, "error");
      //   })
      // });
    }


  }


})();
