(function () {
  'use strict';
  angular
    .module('app')
    .factory('globalService', globalService);

  globalService.$inject = ['$http', 'url', 'user', '$window', '$state', '$resource'];

  function globalService($http, url, $user, $window, $state, $resource) {
    function hashToParams(params) {
      if (params) {
        var params_url = '';

        Object.keys(params).forEach(function (item, index) {
          if (index == 0 && params[item] != null) {
            params_url += '?' + item + '=' + params[item];
          } else if (index != 0 && params[item] != null) {
            params_url += '&' + item + '=' + params[item];
          }
        });
        return params_url;
      } else {
        return '';
      }

    }

    var sections = $resource(url.getApiUrl('sections/:id'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var crmVersion = $resource(url.getApiUrl('system_version/:id'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var notifications = $resource(url.getApiUrl('notifications/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' }, query: { method: 'GET', params: { all: '@_all', per: '@_per', page: '@_page', read: '@_read' }, isArray: false } });
    var users = $resource(url.getApiUrl('users/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' }, query: { method: 'GET', params: { user_id: '@_user_id', lite: '@_lite', user_type_id: '@_user_type_id', use_npn: '@_use_npn' }, isArray: true } });
    var export_database = $resource(url.getApiUrl('export/:id'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var customPayments = $resource(url.getApiUrl('custom_payments/:id'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var agentRates = $resource(url.getApiUrl('users/:user_id/agent_rates/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var companies = $resource(url.getApiUrl('insurance_companies/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var companiesRates = $resource(url.getApiUrl('insurance_companies/:insurance_company_id/company_rates/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' }, query: { method: 'GET', params: { user_id: '@_user_id', lite: '@_lite' }, isArray: true } });
    var resApplications = $resource(url.getApiUrl('customer_applications/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' }, query: { method: 'GET', params: { user_npn: '@user_npn', show_note: '@show_note'}, isArray: true } });
    var getAllAgents = $resource(url.getApiUrl('agents/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' }, query: { method: 'GET', params: { by_status: '@_by_status', only_agencies: '@_only_agencies' }, isArray: true } });
    var getAllManagers = $resource(url.getApiUrl('users/get_managers'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var closures = $resource(url.getApiUrl('general_closures/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var generalInvoiceClosure = $resource(url.getApiUrl('general_invoice_closure/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var generalInovices = $resource(url.getApiUrl('general_invoices/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' }, query: { method: 'GET', params: { date_from: '@_datefrom', date_to: '@_dateto', close: '@_close' }, isArray: true } });
    var payrollSummaries = $resource(url.getApiUrl('payroll_summaries/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' }, query: { method: 'GET', params: { date_from: '@_datefrom', date_to: '@_dateto', close: '@_close' }, isArray: true } });
    var agencies = $resource(url.getApiUrl('agency/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' }, query: { method: 'GET', params: { user_id: '@_user_id' }, isArray: false } });
    var documents = $resource(url.getApiUrl('customer_applications/:app_id/documents/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var smsNotifications = $resource(
      url.getApiUrl('sms_managment/:id.json'),
      { id: '@_id' },
      { 'update': { method: 'PUT' }, query: { method: 'GET', params: { user_id: '@_user_id', page: '@_page', per: '@_per', read: '@_read', order: '@_order', phone_number: '@_phone_number', date: '@_date' }, isArray: false } }
      );

    var customerDirectories = $resource(url.getApiUrl('customer_directory/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' }, query: { method: 'GET', params: { phone: '@_phone', state: '@_state', page: '@_page', per: '@_per', agent: '@_agent' }, isArray: false } });
    function customerDirectoriesStates() {
      return $http.get(url.getApiUrl('customer_directory/states_directories'))
        .then(function (res) {
          return res.data;
        })
    }

    function getUrlPdfGenerator(crmid, token){
      return url.urlPdfGenerator(crmid, token)
    }


    function sendSms(data) {
      return $http.post(url.getApiUrl('sms_managment/send_sms'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function sendMasiveSms(data) {
      return $http.post(url.getApiUrl('sms_managment/send_masive_sms'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function userInformation(type) {
      var resource = type ? 'user_information.json' : 'user_information';
      return $resource(url.getApiUrl(resource + '/:id'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    }

    function loginAs(data) {
      return $http.post(url.getApiUrl('users/login_as'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function getUserByTypes(type) {
      return $http.get(url.getApiUrl('users?user_type_id=' + type))
        .then(function (res) {
          return res.data;
        })
    }
    function refreshPermissions() {
      return $http.get(url.getApiUrl('users/users_permissions'))
        .then(function (res) {
          return res.data;
        })
    }

    function sendApplication(data) {
      return $http.post(url.getApiUrl('customer_applications'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function auditsCustomerApp(id) {
      return $http.get(url.getApiUrl('customer_applications/' + id + '/audits'))
        .then(function (res) {
          return res.data;
        })
    }

    function deleteMemberIds(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/delete_members'), data)
        .then(function (res) {
          return res.data;
        })
    }


    function seachSign(data) {
      return $http.get(url.getApiUrl('customer_applications/' + data.crmid + '/signature?form_type=' + data.form_type))
        .then(function (res) {
          return res.data;
        })
    }
    function seachSignFile(data) {
      return $http.get(url.getApiUrl('customer_applications/' + data.crmid + '/find_signature_link?signed_form_id=' + data.signed_form_id), { responseType: 'blob' })
        .then(function (res) {
          return res;
        })
    }



    function sendCustomerAppsToUsers(data) {
      return $http.post(url.getApiUrl('customer_applications/send_custoemr_apps_to_users'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function updateNotFound(data) {
      return $http.post(url.getApiUrl('comission_periods/update_not_found'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function createPeriod(data) {
      return $http.post(url.getApiUrl('comission_periods'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function updateUnpaid(data) {
      return $http.post(url.getApiUrl('comission_periods/update_unpaid'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function updateClosed(data) {
      return $http.post(url.getApiUrl('comission_periods/update_closed'), data)
        .then(function (res) {
          return res.data;
        })
    }


    function removePaidMember(data) {
      return $http.post(url.getApiUrl('comission_periods/remove_piad_member'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function sendApplicationChangeLife(data) {
      return $http.post(url.getApiUrl('customer_applications/save_customer_application_with_change_life'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function saveNoteOnApplication(data) {
      return $http.post(url.getApiUrl('customer_applications/save_note_on_application'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function saveNoteAgents(data) {
      return $http.post(url.getApiUrl('customer_applications/save_note_agents'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function resolveNoteAgents(data) {
      return $http.post(url.getApiUrl('customer_applications/resolve_note_agents'), data)
        .then(function (res) {
          return res.data;
        })
    }

    

    function findApplication(data) {
      return $http.get(url.getApiUrl('customer_applications/filter_customer.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function allCompanyRates() {
      return $http.get(url.getApiUrl('company_rates.json'))
        .then(function (res) {
          return res.data;
        })
    }



    /*Pasar a resource*/
    function requiredDocuments(data) {
      return $http.post(url.getApiUrl('required_documents'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function updateDocument(data) {
      return $http.post(url.getApiUrl('required_documents/' + data.id + '/change_status'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function applicationsDashboard(data) {
      return $http.get(url.getApiUrl('customer_applications.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }

    function dashboardCounters(){
      return $http.get(url.getApiUrl('home/agent_counters'))
        .then(function (res) {
          return res.data;
        })
    }
    function reportApplications(data) {
      return $http.get(url.getApiUrl('reports/report_managers_applications.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reportDeletedApplication(data) {
      return $http.get(url.getApiUrl('reports/reports_applications_deleteds.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }

    
    function reportDocumentsRequireds(data) {
      return $http.get(url.getApiUrl('reports/report_customer_apps_with_documents_requireds.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reportApplicationsAgents(data) {
      return $http.get(url.getApiUrl('reports/report_agents_applications.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reportsSmsNotifyLog(data) {
      return $http.get(url.getApiUrl('reports/sms_notify_logs_reports.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reportApplicationsAgentsDates(data) {
      return $http.get(url.getApiUrl('reports/report_agents_applications_dates.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }

    function reportOpenEnrollment(data) {
      return $http.get(url.getApiUrl('reports/report_open_enrollment.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reportRegisterType(data) {
      return $http.get(url.getApiUrl('reports/report_register_types.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function getCompanyCounds(data) {
      return $http.get(url.getApiUrl('home/company_counts.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }

    function getCompanyRecords() {
      return $http.get(url.getApiUrl('home/record_of_applications.json'))
        .then(function (res) {
          return res.data;
        })
    }

    

    function reportAgencies(data) {
      return $http.get(url.getApiUrl('reports/report_agencies.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }


    function quoteReport(data) {
      return $http.get(url.getApiUrl('reports/quote_reports.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function getAllApointeds(token) {
      return url.getApiUrl('reports/report_licenses.xlsx?token=' + token)
    }
    function reportProfits(data) {
      return $http.get(url.getApiUrl('profits' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function paidProfitsAgents(data) {
      return $http.post(url.getApiUrl('profits/paid_invoices'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function negativeInvoices(data){
      return $http.get(url.getApiUrl('profits/negative_invoices.json' + hashToParams(data)))
      .then(function (res) {
        return res.data;
      })
    }

    
    function updateInvoice(data) {
      return $http.put(url.getApiUrl('profits/' + data.id), data)
        .then(function (res) {
          return res.data;
        })
    }
    function deleteInvoice(data) {
      return $http.delete(url.getApiUrl('profits/' + data.id))
        .then(function (res) {
          return res.data;
        })
    }
    function destroyAllInvoices(data) {
      return $http.post(url.getApiUrl('profits/destroy_all_invoices' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reportProfitsAgents(data) {
      return $http.get(url.getApiUrl('profits/profits_agents' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reportProfitsAgentsDetail(data) {
      return $http.get(url.getApiUrl('profits/profits_agent_detail.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }




    function getPaymentCustomerApplication(id) {
      return $http.get(url.getApiUrl('customer_applications/' + id + '/payments_customer_applications'))
        .then(function (res) {
          return res.data;
        })
    }

    function getInsuranceCredential(id) {
      return $http.get(url.getApiUrl('customer_applications/' + id + '/insurance_credentials'))
        .then(function (res) {
          return res.data;
        })
    }

    function saveInsuranceCredential(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.customer_application_id + '/save_insurance_credentials'), data)
        .then(function (res) {
          return res.data;
        })
    }


    function savePaymentCustomerApplication(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.customer_application_id + '/save_payments_customer_applications'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function migrateMembersId(data) {
      return $http.post(url.getApiUrl('customer_applications/migrating_memeber_ids'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function deletePaymentFromCustomerApp(data) {
      return $http.post(url.getApiUrl('customer_applications/delete_payments_customer_applications'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function togglePaymentSubscription(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/toggle_payment_subscription'))
        .then(function (res) {
          return res.data;
        })
    }

    function updateMainApplicant(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/update_main_applicant'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function updateSpouseApplicant(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/update_spouse_applicant'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function updateDependentApplicant(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/update_dependent_applicant'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function updateBillInformation(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/update_bill_information'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function updateInsuranceData(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/update_insurance_data'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function updateStatusApplication(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/update_status_application'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function updateCustomerApplication(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/update_customer_application'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function processApplication(data) {
      return $http.put(url.getApiUrl('customer_applications/' + data.id), data)
        .then(function (res) {
          return res.data;
        })
    }
    function setConlfictAgent(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/conflict_agents'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function resolvConflict(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/resolve_conflict'), data)
        .then(function (res) {
          return res.data;
        })
    }


    function setMemberId(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/set_member_id'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function changeAgent(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/change_agent'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function changeNpn(data) {
      return $http.post(url.getApiUrl('customer_applications/' + data.id + '/change_npn'), data)
        .then(function (res) {
          return res.data;
        })
    }



    function deleteDocument(data) {
      return $http.delete(url.getApiUrl('required_documents/' + data.id + '/delete_document'), data)
        .then(function (res) {
          return res.data;
        })
    }


    //Miscelaneos
    function getReasons() {
      return $http.get(url.getApiUrl('miscelaneos/baja_reasons'))
        .then(function (res) {
          return res.data;
        })
    }

    function getSystemVersions() {
      return $http.get(url.getApiUrl('miscelaneos/system_versions'))
        .then(function (res) {
          return res.data;
        })
    }

    function getUserTypes() {
      return $http.get(url.getApiUrl('miscelaneos/user_types'))
        .then(function (res) {
          return res.data;
        })
    }
    function blockUser(data) {
      return $http.post(url.getApiUrl('users/block_user'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function resetPassword(data) {
      return $http.post(url.getApiUrl('users/reset_password'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function toggleTaxSaving(id) {
      return $http.post(url.getApiUrl('users/' + id + '/toggle_tax_saving'))
        .then(function (res) {
          return res.data;
        })
    }


    function typeInsuranceCompanies(data) {
      return $http.get(url.getApiUrl('miscelaneos/type_insurance_companies.json'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function getOnlyTypeInsuranceCompanies(data) {
      return $http.get(url.getApiUrl('miscelaneos/get_only_type_insurance_companies.json'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function addPlan(data) {
      return $http.post(url.getApiUrl('insurance_companies/add_plan'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function updateCompanyProducts(data) {
      return $http.put(url.getApiUrl('insurance_companies/' + data.id), data)
        .then(function (res) {
          return res.data;
        })
    }
    function updatePlan(data) {
      return $http.put(url.getApiUrl('insurance_companies/update_plan'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function type_insurance_companies(data) {
      return $http.get(url.getApiUrl('miscelaneos/type_insurance_companies.json'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function get_states(data) {
      return $http.get(url.getApiUrl('miscelaneos/get_states'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function get_application_types(data) {
      return $http.get(url.getApiUrl('miscelaneos/get_application_types'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function reports_npns_useds(data) {
      return $http.get(url.getApiUrl('reports/report_npn_used.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reportPendingLinkLog(data) {
      return $http.get(url.getApiUrl('reports/report_pending_link_applications.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reports_customer_applications_managers(data) {
      return $http.get(url.getApiUrl('reports/report_managers_applications.json'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function reports_applications_postal_code(data) {
      return $http.get(url.getApiUrl('reports/report_customer_applications_postal_code.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reportCustomersBirthdays(data) {
      return $http.get(url.getApiUrl('reports/report_customer_birthdays.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reportTaxSaving(data) {
      return $http.get(url.getApiUrl('reports/tax_savings.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }
    function reportAppsWithConflicts() {
      return $http.get(url.getApiUrl('reports/customer_applications_conflicts.json'))
        .then(function (res) {
          return res.data;
        })
    }

    function reportPdf(resource, data) {
      if (data) {
        return url.geturlReportPdf(resource, hashToParams(data));
      } else {
        return url.geturlReportPdfWithoutParams(resource);
      }
    }

    function reportExcel(resource, data) {
      if (data) {
        return url.geturlReportExcel(resource, hashToParams(data));
      } else {
        return url.geturlReportExcelWithoutParams(resource);
      }
    }

    function reportExcel(resource, data) {
      if (data) {
        return url.geturlReportExcel(resource, hashToParams(data));
      } else {
        return url.geturlReportExcelWithoutParams(resource);
      }
    }

    function getUrlExcel(resource, data) {
      return url.urlExcel(resource + hashToParams(data));
    }

    function reportCSV(resource, data) {
      return url.getUrlReportCsv(resource, hashToParams(data));
    }

    function reportPdfWithAction(action, resource, params) {
      return url.getUrlReportPdfController(action, resource, hashToParams(params))
    }

    function count_applications_send(data) {
      return $http.get(url.getApiUrl('miscelaneos/count_applications_send'))
        .then(function (res) {
          return res.data;
        })
    }
    function getPeriods(data) {
      return $http.get(url.getApiUrl('miscelaneos/periods'))
        .then(function (res) {
          return res.data;
        })
    }
    function application_processed_by_managers(data) {
      return $http.get(url.getApiUrl('miscelaneos/application_processed_by_managers'))
        .then(function (res) {
          return res.data;
        })
    }

    function getUserLicenses(data) {
      return $http.get(url.getApiUrl('licenses' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }

    function saveLicenses(data) {
      return $http.post(url.getApiUrl('licenses'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function clearLicense(data) {
      return $http.post(url.getApiUrl('licenses/destroy_license'), data)
        .then(function (res) {
          return res.data;
        })
    }

    
    function reportUnpaidApplications(data) {
      return $http.get(url.getApiUrl('reports/report_diff_payments.json' + hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }

    function reportAllInvoices(data) {
      return $http.post(url.getApiUrl('profits/all_profits'), data)
        .then(function (res) {
          return res.data;
        })
    }

    function overideReport(data) {
      return $http.get(url.getApiUrl('overide.json'+ hashToParams(data)))
        .then(function (res) {
          return res.data;
        })
    }

    





    function getCustomerApplication(data) {
      return $http.get(url.getApiUrl('customer_applications/get_customer_application_to_change_life.json?customer_application_id=' + data.id))
        .then(function (res) {
          return res.data;
        })
    }

    function setLocalCA(data) {
      var key = "customer_app_" + data.id;
      $window.localStorage.setItem(key, JSON.stringify(data));
      return true;
    }

    function getLocalCA(key) {
      var data = $window.localStorage.getItem(key);
      if (data != null) {
        return JSON.parse(data);
      }
    }

    function getHomeCounters(type) {
      return $http.get(url.getApiUrl('home/counters'))
        .then(function (res) {
          return res.data;
        })
    }

    function sendNotifySync(data){
      return $http.post(url.getApiUrl('notifications/send_notification_to_agent'), data)
      .then(function (res) {
        return res.data;
      })
    }

    function retryPayrollSummary(id){
      return $http.post(url.getApiUrl('payroll_summaries/'+id+'/retry'))
      .then(function (res) {
        return res.data;
      })
    }

    

    return {
      getUserByTypes: getUserByTypes,
      sections: sections,
      users: users,
      getUserTypes: getUserTypes,
      blockUser: blockUser,
      companies: companies,
      typeInsuranceCompanies: typeInsuranceCompanies,
      addPlan: addPlan,
      updateCompanyProducts: updateCompanyProducts,
      updatePlan: updatePlan,
      type_insurance_companies: type_insurance_companies,
      get_states: get_states,
      get_application_types: get_application_types,
      resApplications: resApplications,
      sendApplication: sendApplication,
      requiredDocuments: requiredDocuments,
      updateDocument: updateDocument,
      deleteDocument: deleteDocument,
      processApplication: processApplication,
      getAllAgents: getAllAgents,
      setConlfictAgent: setConlfictAgent,
      applicationsDashboard: applicationsDashboard,
      setMemberId: setMemberId,
      getAllManagers: getAllManagers,
      reports_customer_applications_managers: reports_customer_applications_managers,
      reportApplications: reportApplications,
      resolvConflict: resolvConflict,
      reportPdf: reportPdf,
      reportCSV: reportCSV,
      reportPdfWithAction: reportPdfWithAction,
      countApplicationsSend: count_applications_send,
      applicationProcessedByManagers: application_processed_by_managers,
      reportsApplicationsPostalCode: reports_applications_postal_code,
      reportCustomersBirthdays: reportCustomersBirthdays,
      reportApplicationsAgents: reportApplicationsAgents,
      getCustomerApplication: getCustomerApplication,
      setLocalCA: setLocalCA,
      getLocalCA: getLocalCA,
      sendApplicationChangeLife: sendApplicationChangeLife,
      updateMainApplicant: updateMainApplicant,
      updateSpouseApplicant: updateSpouseApplicant,
      updateDependentApplicant: updateDependentApplicant,
      updateBillInformation: updateBillInformation,
      updateInsuranceData: updateInsuranceData,
      reportDocumentsRequireds: reportDocumentsRequireds,
      updateStatusApplication: updateStatusApplication,
      updateCustomerApplication: updateCustomerApplication,
      findApplication: findApplication,
      companiesRates: companiesRates,
      allCompanyRates: allCompanyRates,
      agentRates: agentRates,
      reportApplicationsAgentsDates: reportApplicationsAgentsDates,
      getPeriods: getPeriods,
      reportProfits: reportProfits,
      reportProfitsAgents: reportProfitsAgents,
      reportProfitsAgentsDetail: reportProfitsAgentsDetail,
      updateInvoice: updateInvoice,
      deleteInvoice: deleteInvoice,
      closures: closures,
      updateNotFound: updateNotFound,
      updateUnpaid: updateUnpaid,
      removePaidMember: removePaidMember,
      generalInovices: generalInovices,
      getOnlyTypeInsuranceCompanies: getOnlyTypeInsuranceCompanies,
      generalInvoiceClosure: generalInvoiceClosure,
      reportTaxSaving: reportTaxSaving,
      toggleTaxSaving: toggleTaxSaving,
      reportAppsWithConflicts: reportAppsWithConflicts,
      saveNoteOnApplication: saveNoteOnApplication,
      getPaymentCustomerApplication: getPaymentCustomerApplication,
      getUserLicenses: getUserLicenses,
      saveLicenses: saveLicenses,
      updateClosed: updateClosed,
      quoteReport: quoteReport,
      customPayments: customPayments,
      export_database: export_database,
      createPeriod: createPeriod,
      documents: documents,
      refreshPermissions: refreshPermissions,
      migrateMembersId: migrateMembersId,
      reportUnpaidApplications: reportUnpaidApplications,
      getAllApointeds: getAllApointeds,
      destroyAllInvoices: destroyAllInvoices,
      payrollSummaries: payrollSummaries,
      agencies: agencies,
      smsNotifications: smsNotifications,
      reportsSmsNotifyLog: reportsSmsNotifyLog,
      loginAs: loginAs,
      userInformation: userInformation,
      sendSms: sendSms,
      reportExcel: reportExcel,
      sendMasiveSms: sendMasiveSms,
      reportAllInvoices: reportAllInvoices,
      reportAgencies: reportAgencies,
      getHomeCounters: getHomeCounters,
      sendCustomerAppsToUsers: sendCustomerAppsToUsers,
      auditsCustomerApp: auditsCustomerApp,
      getReasons: getReasons,
      getUrlExcel: getUrlExcel,
      seachSign: seachSign,
      seachSignFile: seachSignFile,
      changeAgent: changeAgent,
      reports_npns_useds: reports_npns_useds,
      changeNpn: changeNpn,
      getSystemVersions: getSystemVersions,
      crmVersion: crmVersion,
      notifications: notifications,
      overideReport: overideReport,
      customerDirectories: customerDirectories,
      customerDirectoriesStates: customerDirectoriesStates,
      getUrlPdfGenerator: getUrlPdfGenerator,
      savePaymentCustomerApplication: savePaymentCustomerApplication,
      reportOpenEnrollment: reportOpenEnrollment,
      reportRegisterType: reportRegisterType,
      getCompanyCounds: getCompanyCounds,
      getCompanyRecords: getCompanyRecords,
      getInsuranceCredential: getInsuranceCredential,
      saveInsuranceCredential: saveInsuranceCredential,
      saveNoteAgents: saveNoteAgents,
      resolveNoteAgents: resolveNoteAgents,
      paidProfitsAgents: paidProfitsAgents,
      clearLicense: clearLicense,
      reportDeletedApplication: reportDeletedApplication,
      deleteMemberIds: deleteMemberIds,
      negativeInvoices: negativeInvoices,
      dashboardCounters: dashboardCounters,
      reportPendingLinkLog: reportPendingLinkLog,
      sendNotifySync: sendNotifySync,
      retryPayrollSummary: retryPayrollSummary,
      resetPassword: resetPassword
    };
  }

})();
