(function(){
  'use strict';
  angular
  .module('app')
  .component('dashboardManager', {
    templateUrl: 'app/components/dashboard-manager/dashboard.html',
    controller: DashboardManagerController
  });

  DashboardManagerController.$inject = ['user', '$rootScope', '$state', '_', 'globalService','$uibModal','$window','$timeout'];

  function DashboardManagerController($user, $rootScope, $state, _,$globalService, $uibModal, $window,$timeout) {
    var vm = this;
    vm.data = {applications: []};
    vm.loadingRequests = false;
    vm.showCurrentApplication = false;
    vm.loadingData = true;
    vm.requireDocuments = [];
    vm.listRequireDocuments = ['Income','Social Security Number', 'Status Migratory', 'Relocation','Lost Cover', 'Others'];
    vm.tabPanel = {
      personalData: true,
      insuranceData: false,
      billingInformation: false,
      requiredData: false,
    }
    vm.statuses = [
      {key: 0, value: "Enviada"},
      {key: 1, value: "En Proceso"},
      {key: 2, value: "Aprobada"},
      {key: 3, value: "Conflicto de Agentes"},
      {key: 4, value: "Activa"},
      {key: 5, value: "Baja"}
    ]
    
    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
      vm.currentPage = 1;
      vm.per = 20;
      $rootScope.$emit('uploadBreadCrum', {current: 'Dashboard', before: 'Home'});
      vm.params = { location: 'dashboard_managers', manager_id: null, page: 1, per: vm.per };
      if($state.current.name == 'myApplications'){
        vm.params.manager_id = vm.currentUser.id;
      }
      // $globalService.type_insurance_companies()
      // .then(function(res){
      //   vm.type_insurance_companies = res;
      // })
      $globalService.applicationsDashboard(vm.params)
      .then(function(res){
        vm.data = res;
        vm.currentPage = vm.data.current_page;
        vm.send = false;
        vm.data.applications = res.data;
        vm.loadingData = false;
      })
      // $globalService.companies.query(function(res){
      //   vm.insurances = res;
      // })

      $globalService.countApplicationsSend()
      .then(function(res){
        vm.total_applications = res;
      })
      $globalService.applicationProcessedByManagers()
      .then(function(res){
        vm.total_my_applications = res;
      })
      // $globalService.get_states()
      // .then(function(res) {
      //   vm.states = res;
      // })
      // $globalService.companies.query().$promise.then(function name(res) {
      //   vm.companies = res;  
      // }, function(err) {
      //   vm.send = false;
      // })
    }

    vm.showChangeLife = function (application) {
      $globalService.resApplications.get({id: application.id, show_note: true}).$promise
      .then(function (res) {
        var modal = $uibModal.open({
          animation: true,
          component: 'lifechanges',
          windowClass: 'show',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            currentApplication: function () {
              return res;
            }
          }
        });
      })
    }

    vm.pageChanged = function () {
      vm.send = true;
      vm.loadingData = true;
      vm.currentPage = vm.data.current_page;
      var data = {location: 'dashboard_managers', manager_id: null, page: vm.currentPage, per: vm.per, status: vm.params.status};
      if($state.current.name == 'myApplications'){
        data.manager_id = vm.currentUser.id;
      }
      $globalService.applicationsDashboard(data)
        .then(function (res) {
          vm.send = false;
          vm.data.applications = _.map(res.data, function(data){
            data.urlReport = $globalService.reportPdf('customer_application',{application_id: data.id});
            return data;
          });
          vm.loadingData = false;
        })
    };

    vm.process = function (data) {
      if(data.status == 1){
          $state.go('procesarApplication', {crmid: data.id, user_npn: null });
      }else{
        var modal = $uibModal.open({
          animation: true,
          component: 'modalProcess',
          windowClass: 'show',
          backdrop: 'static',
          size: 'md',
          resolve: {
            crmid: function () {
              return data.id;
            },
            currentApplication: function () {
              return data;
            }
          }
        });
  
        modal.result.then(function (res) {
          $state.go('procesarApplication',{crmid: data.id, user_npn: res.user_npn});
        }, function (err) {
          swal('Error', err.data.message, 'error');
        });
      }
    }

    vm.conflics = function(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'conflictAgents',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md',
        resolve: {
          currentApplication: function(){
            return data;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      }, function(err){
        swal('Error',err.data.message,'error');
      });
    }

    vm.setActiveTab = function(tab){
      _.forEach(Object.keys(vm.tabPanel), function(key){
        if(vm.tabPanel[key]){
          vm.tabPanel[key] = false;
        }
      })
      vm.tabPanel[tab] = true;
    }

    vm.setIdMember = function(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'memberId',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md',
        resolve: {
          currentApplication: function(){
            return data;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      });
    }

    

    vm.changeLife = function(data){
      swal({
        title: '¿Esta seguro de hacer el cambio de vida?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.getCustomerApplication(data)
            .then(function(res){
              $globalService.setLocalCA(res);
              if($window.localStorage.hasOwnProperty('customer_app_'+res.id)){
                if(vm.currentUser.permits_granted.health_form_v2.active){
                  $state.go('healthApplicationV2',{id: res.id});
                }else{
                  $state.go('applications',{id: 'customer_app_'+res.id});
                }
                resolve();
              }
            }, function(err) {
              Swal('Stop',err.data.message,'warning');
            });
            
          });
        },
        allowOutsideClick: false,
      }).then(function(data) {});

      
    }

    vm.goToCreateApplication = function(){
      console.log(vm.currentUser.permits_granted.health_form_v2)
      if(vm.currentUser.permits_granted.health_form_v2.active){
        $state.go('healthApplicationV2',{id: null});
      }else{
        $state.go('applications',{id: null});
      }
    }


    function findApplication(){
      vm.loadingData = true;
      $globalService.findApplication(vm.params)
      .then(function(res){
        
        vm.data.applications = _.map(res.data, function (data) {
          data.single_customer = data.customer.name + ' ' + data.customer.last_name;
          data.single_agent = data.user.full_name;
          data.single_state = data.state.name;
          data.urlReport = $globalService.reportPdf('customer_application', { application_id: data.id });
          return data;
        });
        vm.loadingData = false;
      })
    }

    vm.showNote = function (application) {
      $globalService.resApplications.get({id: application.id, show_note: true}).$promise
      .then(function (res) {
        vm.loadingRequests = false;
        var modal = $uibModal.open({
          animation: true,
          component: 'applicationNotes',
          windowClass: 'show',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            currentApplication: function () {
              return res;
            }
          }
        });
      })
    }

    vm.sendNoteApplication = function (application) {
      swal({
        title: 'Write the note to the agent',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function (note) {
          if (note) {
            application.note = note;
          }
          return $globalService.updateCustomerApplication(application)
            .then(function (res) {
              vm.send = false;
              vm.loadingApp = false;
              Swal('Exito', res.message, 'success');
            }, function (err) {
              vm.loadingApp = false;
              Swal('Error', err.data.message, 'warning');
            });
        }
      })
    }

    vm.createNote = function(application) {
      swal({
        title: 'Write a note for later',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function (note) {
          if (note) {
            application.agent_notes = note;
          }
          return $globalService.saveNoteOnApplication(application)
            .then(function (res) {
              vm.send = false;
              vm.loadingApp = false;
              Swal('Exito', res.message, 'success');
            }, function (err) {
              vm.loadingApp = false;
              Swal('Error', err.data.message, 'warning');
            });
        }
      })
    }

    vm.viewNote = function(application) {
      Swal('Note', application.agent_notes, 'info');
    }

    vm.download = function(){
      var new_data = _.reduce(vm.data.applications, function(memo, data) {
        console.log(data)
        memo.push({
          "CRM ID": data.id,
          "Name": data.customer.name,
          "Lastname": data.customer.last_name,
          "Member ID": data.member_id,
          "D.O.B": data.birthday,
          "Phone": data.customer.phone,
          "Company": data.company,
          "Plan": data.plan,
          "Net Premium": data.beforehand
        });
        return memo
      },[])

      var name = moment().format('MM-DD') + '_applications_';
      var a = document.createElement("a");
      var json_pre = angular.toJson(new_data);
      var fileName = name+".csv";
      
      var csv = Papa.unparse(json_pre);
 
      if (window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
          type: "text/csv;charset=utf-8;"
        });
        navigator.msSaveBlob(blob, fileName);
      } else {
 
        a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    }

    vm.consultApplicationsAll = function(){
      vm.data.applications = [];
      $globalService.applicationsDashboard(vm.params)
      .then(function(res){
        vm.data = res;
        vm.currentPage = vm.data.current_page;
        vm.send = false;
        vm.data.applications = res.data;
        vm.loadingData = false;
      })
    }

    vm.createPayment = function(application){
      var modal = $uibModal.open({
        animation: true,
        component: 'paymentsApps',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
      });
    }

    vm.viewCustomerSms = function(application){
      $state.go('customerSms',{crmid: application.id});
    }

    vm.sendSyncNotify = function(application){
      var data = {
        user_id: application.user_id
      }
      swal({
        title: 'Are you sure to send sync notification? to: '+application.user+'?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.sendNotifySync(data)
            .then(function(res){
              resolve(res);
            }, function(err) {
              Swal('Error',err.data.message,'warning');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function(res) {
        if(res.value){
          swal('Exito',res.value.message,'success');
        }
      });
    }

    $rootScope.$on('searchApp', function(evt,data){
      vm.params = data.params;
      findApplication()
    })
    





  }


})();
